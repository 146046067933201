import { BehaviorSubject } from "rxjs";
import moment from "moment";
import { Inspection, InspectionType } from '../../domain/entities/Inspection'
import { ListInspections } from '../../domain/usecases/ListInspections'

export type InspectionsState = {
  type: "loading" | "show-inspections";
  timeSelectionState:
    | {
        type:
          | "today"
          | "yesterday"
          | "this-week"
          | "last-week"
          | "this-month"
          | "last-month";
      }
    | {
        type: "custom";
        from: number;
        to: number;
      };
  inspectionTypeFilters: InspectionTypeFilter[];
  clientFilters: ClientFilter[]
  inspections: Inspection[];
};

export type InspectionTypeFilter = {
  type: InspectionType
  selected: boolean
}

export type ClientFilterOption = "KOTA" | "MOPPI"

export type ClientFilter = {
  type: ClientFilterOption
  selected: boolean
}

const initialInspectionTypeFilters: InspectionTypeFilter[] = [
  {
    type: "daily-inspection",
    selected: false
  },
  {
    type: "maintenance-hangar-inspection",
    selected: false
  },
  {
    type: "terminus-inspection",
    selected: false
  },
]

const initialClientFilters: ClientFilter[] = [
  {
    type: "KOTA",
    selected: false
  },
  {
    type: "MOPPI",
    selected: false
  },
]

export class InspectionsViewModel {
  private _state = new BehaviorSubject<InspectionsState>({
    type: "loading",
    timeSelectionState: { type: "today" },
    inspectionTypeFilters: initialInspectionTypeFilters,
    clientFilters: initialClientFilters,
    inspections: [],
  });

  readonly state = this._state.asObservable();

  private loadedInspections: Inspection[] = []

  constructor(private readonly listInspections: ListInspections) {
    this.refreshInspections();
  }

  currentState(): InspectionsState {
    return this._state.getValue();
  }

  selectTimePeriod(
    period:
      | "today"
      | "yesterday"
      | "this-week"
      | "last-week"
      | "this-month"
      | "last-month"
  ) {
    const currentState = this._state.value;
    this._state.next({ ...currentState, timeSelectionState: { type: period } });
    this.refreshInspections();
  }

  toggleInspectionTypeFilter(filterType: InspectionType) {    
    const currentState = this._state.value;
  
    const updated = currentState.inspectionTypeFilters.map((it) => {
      if (it.type === filterType) {
        return {type: it.type, selected: !it.selected }
      } else {
        return it
      }
    })

    this._state.next({ ...currentState, inspectionTypeFilters: updated });
    this.showFilteredInspections()
  }

  toggleClientFilter(filterType: ClientFilterOption) {
    const currentState = this._state.value;
  
    const updated = currentState.clientFilters.map((it) => {
      if (it.type === filterType) {
        return {type: it.type, selected: !it.selected }
      } else {
        return it
      }
    })

    this._state.next({ ...currentState, clientFilters: updated });
    this.showFilteredInspections()
  }

  refreshInspections() {
    const currentState = this._state.value;

    this._state.next({ ...currentState, type: "loading" });

    let from: number;
    let to: number;

    switch (currentState.timeSelectionState.type) {
      case "today":
        from = moment().startOf("day").valueOf();
        to = moment().endOf("day").valueOf();
        break;
      case "yesterday":
        from = moment().subtract(1, "days").startOf("day").valueOf();
        to = moment().subtract(1, "days").endOf("day").valueOf();
        break;
      case "this-week":
        from = moment().startOf("week").valueOf();
        to = moment().endOf("week").valueOf();
        break;
      case "last-week":
        from = moment().subtract(1, "week").startOf("week").valueOf();
        to = moment().subtract(1, "week").endOf("week").valueOf();
        break;
      case "this-month":
        from = moment().startOf("month").valueOf();
        to = moment().endOf("month").valueOf();
        break;
      case "last-month":
        from = moment().subtract(1, "month").startOf("month").valueOf();
        to = moment().subtract(1, "month").endOf("month").valueOf();
        break;
      case "custom":
        from = currentState.timeSelectionState.from;
        to = currentState.timeSelectionState.to;
        break;
    }

    this.listInspections
      .execute(from, to)
      .then((result) => {
      this.loadedInspections = result;
        this.loadedInspections = result;
        this.showFilteredInspections()        
      })
      .catch((_error) => {
        this.loadedInspections = [];
        this.showFilteredInspections()
      });
  }

  // NOTE: it might be better to use a table implementation that offers filtering support out of the box. Currently Material UI library has
  // data grid that could be suitable, but full implementation requires commercial license.
  private showFilteredInspections() {
    const currentState = this._state.value;

    let step1 = this.loadedInspections
    if (currentState.inspectionTypeFilters.find((it) => it.selected) !== undefined) {
      step1 = this.loadedInspections.filter((it) => currentState.inspectionTypeFilters.find((filter) => filter.type === it.type && filter.selected))
    }

    let step2 = step1
    if (currentState.clientFilters.find((it) => it.selected) !== undefined) {
      step2 = step1.filter((it) => currentState.clientFilters.find((filter) => filter.type === it.client && filter.selected))
    }
    
    this._state.next({
      type: "show-inspections",
      inspections: step2,
      inspectionTypeFilters: currentState.inspectionTypeFilters,
      clientFilters: currentState.clientFilters,
      timeSelectionState: currentState.timeSelectionState,
    })
  }

}
