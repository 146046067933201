import { DomainContext } from "../../domain/DomainContext"
import React, { useEffect, useState, useContext } from "react"
import {
  CleaningRequestsViewModel,
  RequestsState,
} from "./CleaningRequestViewModel"
import { RequestsTable } from "./RequestsTable"
import { RequestImageDialog } from "./RequestImageDialog"
import { API, Auth } from "aws-amplify"
import { ExportButton } from "./ExportButton"
import { Box, LinearProgress } from "@mui/material"

export function useViewModel(): [RequestsState, CleaningRequestsViewModel] {
  const { getCleaningRequestsViewModel } = useContext(DomainContext)
  const [viewModel] = useState(() => getCleaningRequestsViewModel())
  const [state, setState] = useState<RequestsState>(
    viewModel.currentState()
  )

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [viewModel])

  return [state, viewModel]
}

export function CleaningRequestsPage() {
  const [state] = useViewModel()

  const [image, setImage] = React.useState<string | undefined>(undefined)

  const closeDialog = () => setImage(undefined)

  const loadImage = async (id: string) => {
    // TODO: hacky solution to pass a special string here. refactor this.
    setImage("loading")
    const session = await Auth.currentSession()

    // TODO: move api call to backend class and hide it behind view model
    await API.get("client-api", "/cleaning-requests/images/" + id, {
      headers: {
        Authorization: session.getIdToken().getJwtToken(),
        accept: "image/jpeg",
        "Cache-Control": "private, max-age=31536000",
      },
      responseType: "blob",
    })
      .then((response) => setImage(URL.createObjectURL(response)))
      .catch((error) => setImage(undefined))
  }

  const loadingBar = () => {
    if (state.status === "loading") {
      return <LinearProgress />
    }
  }

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={4}>
        <ExportButton requests={state.requests} />
      </Box>
      <RequestImageDialog image={image} onClose={closeDialog} />
      <RequestsTable
        requests={state.requests}
        openImage={(id: string) => loadImage(id)}
      />
    </>
  )
}
