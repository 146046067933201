import { Button } from "@mui/material";
import { ExportToCsv } from "export-to-csv";
import { Inspection } from "../../domain/entities/Inspection";
import moment from "moment";

type Props = {
  inspections: Inspection[];
};

export function ExportButton(props: Props) {


  const exportCsv = () => {
    const options = {
      filename: "Tarmo_tarkastukset",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    const data = props.inspections.map((inspection) => {
      return {
        type: inspection.type,
        vehicle: inspection.vehicle,
        start: moment(inspection.startTimestamp).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(inspection.endTimestamp).format("YYYY-MM-DD HH:mm:ss"),
        client: inspection.client,
        nfc: inspection.nfcUsed,
      };
    });
    csvExporter.generateCsv(data);
  };

  return (
    <Button
      variant="outlined"
      color="primary"    
      onClick={exportCsv}
      sx={{float: "right"}}
    >
      Lataa CSV
    </Button>
  );
}
