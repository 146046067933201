import { Inspection } from '../entities/Inspection'
import { Backend } from '../interfaces/Backend'

export interface ListInspections {
  execute(from: number, to: number): Promise<Inspection[]>
}

export class ListInspectionsImpl implements ListInspections {

  constructor(
    private readonly backend: Backend
  ) {}


  async execute(from: number, to: number): Promise<Inspection[]> {
    const result = await this.backend.listInspections(from, to)

    if (result.success) {
      return result.data
    } else {
      return []
    }
  }
}
