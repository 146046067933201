import { Button } from "@mui/material"
import { ExportToCsv } from "export-to-csv"
import { Cleaning } from "../../domain/entities/Cleaning"
import moment from "moment"

type Props = {
  cleanings: Cleaning[]
}

export function ExportButton(props: Props) {
  const exportCsv = () => {
    const options = {
      filename: "MOPPI_siivoukset",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }

    const csvExporter = new ExportToCsv(options)

    const data = props.cleanings.map((cleaning) => {
      return {
        type: cleaning.cleaningType,
        vehicle: cleaning.vehicle,
        start: moment(cleaning.startTime).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(cleaning.endTime).format("YYYY-MM-DD HH:mm:ss"),
        nfc: cleaning.nfcId ?? "-",
        floorMachineWashed: cleaning.floorMachineWashed ?? "-",
        workLocation: cleaning.workLocation ?? "-",
        method: cleaning.method ?? "-"
      }
    })
    csvExporter.generateCsv(data)
  }

  return (
    <Button
      sx={{ float: "right" }}
      variant="outlined"
      color="primary"
      onClick={exportCsv}
    >
      Lataa CSV
    </Button>
  )
}
