import React from "react";
import Button from "@mui/material/Button";
import {Grid, Typography } from "@mui/material";
import { Auth } from "aws-amplify";

export const LoginPage = () => {

  const login = async () =>
    await Auth.federatedSignIn({ customProvider: "AzureAD" });

  return (
    <Grid
      container
      spacing={3}
      alignContent="stretch"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >

    <Typography
      variant="h5"
      color="inherit"
      noWrap
      component="div"            
      sx={{ marginLeft: "50px", marginTop: "100px" }}
      >
        VR MOPPI
      </Typography>
      
      <Button
        variant="contained"
        color="primary"
        onClick={login}
        sx={{
          marginTop: "10px",      
          marginLeft: "50px"    
        }}
      >
        Kirjaudu AD-tunnuksella
      </Button>
    </Grid>
  );
};
