import { UserGroup } from '../entities/User'
import { Backend } from '../interfaces/Backend'

export interface UpdateUser {
  execute(email: string, group: UserGroup): Promise<void>
}

export class UpdateUserImpl implements UpdateUser {

  constructor(
    private readonly backend: Backend
  ) {}

  async execute(email: string, group: UserGroup): Promise<void> {
    const result = await this.backend.updateUserGroup(email, group)

    if (!result.success) {
      throw new Error("Update failed")
    }
  }

}