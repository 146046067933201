import {
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

export type PeriodOption =
  | "today"
  | "yesterday"
  | "this-week"
  | "last-week"
  | "this-month"
  | "last-month";

type Props = {
  selectTimePeriod: (period: PeriodOption) => void;
};

// TODO: move to common components
export function Timeselector(props: Props) {

  const [timePeriod, setTimePeriod] = React.useState("today");

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTimePeriod(event.target.value);
    props.selectTimePeriod(event.target.value as PeriodOption);
  };

  return (
    <FormControl variant="outlined" sx={{minWidth: 220, float: "left", height: "75px"}}>
      <InputLabel id="demo-simple-select-outlined-label">Aikaväli</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={timePeriod}
        onChange={handleChange}
        label="Aikaväli"
      >
        <MenuItem value="today">Tänään</MenuItem>
        <MenuItem value="yesterday">Eilen</MenuItem>
        <MenuItem value="this-week">Kuluva viikko</MenuItem>
        <MenuItem value="last-week">Viime viikko</MenuItem>
        <MenuItem value="this-month">Kuluva kuukausi</MenuItem>
        <MenuItem value="last-month">Viime kuukausi</MenuItem>
      </Select>
    </FormControl>
  );
}
