import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
  TableSortLabel,
} from "@mui/material"
import React from "react"
import moment from "moment"
import { Cleaning } from "../../domain/entities/Cleaning"

export type CleaningRow = {
  cleaningType: string
  vehicle: string
  startTime: string
  endTime: string
  durationSeconds: number
  nfcId: string
  floorMachineWashed: string
  workLocation: string
  method: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = "asc" | "desc"

type Props = {
  cleanings: Cleaning[]
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function CleaningsTable(props: Props) {

  const [order, setOrder] = React.useState<Order>("asc")
  const [orderBy, setOrderBy] = React.useState<keyof CleaningRow>("startTime")
  const [page, setPage] = React.useState(0)

  const rowsPerPage = 200

  const rows: CleaningRow[] = props.cleanings.map((it) => {

    let floorMachineWashed = ""

    if (it.floorMachineWashed) {
      floorMachineWashed = "Kyllä"
    } else if (it.floorMachineWashed !== undefined && !it.floorMachineWashed) {
      floorMachineWashed = "Ei"
    }

    let method = "-"
    switch (it.method) {
      case "machine-wash": {
        method = "Koneellinen pesu"
        break
      }
      case "mopping": {
        method = "Moppaus"
        break
      }
      case "periodical": {
        method = "Jaksollinen"
        break
      }
      case undefined: {
        method = "-"
        break
      }
      default: method = "Tuntematon"
    }

    let workLocation = it.workLocation ?? "-"
    if (workLocation === "OTHER") {
      workLocation = "Muu sijainti"
    }

    return {
      cleaningType: it.cleaningType,
      vehicle: it.vehicle,
      startTime: moment(it.startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(it.endTime).format("YYYY-MM-DD HH:mm:ss"),
      durationSeconds: Math.round((it.endTime - it.startTime) / 1000),
      nfcId: it.nfcId ?? "-",
      floorMachineWashed: floorMachineWashed,
      workLocation: workLocation,
      method: method
    }
  })

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CleaningRow
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const createSortHandler =
    (property: keyof CleaningRow) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  return <>
    <Box m={2} pb={4}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <SortableCell
                title="Tyyppi"
                selectedOrderBy={orderBy}
                orderBy="cleaningType"
                order={order}
                sortHandler={createSortHandler("cleaningType")}
              />
              <SortableCell
                title="Runko"
                selectedOrderBy={orderBy}
                orderBy="vehicle"
                order={order}
                sortHandler={createSortHandler("vehicle")}
              />
              <SortableCell
                title="Aloitus"
                selectedOrderBy={orderBy}
                orderBy="startTime"
                order={order}
                sortHandler={createSortHandler("startTime")}
              />
              <SortableCell
                title="Lopetus"
                selectedOrderBy={orderBy}
                orderBy="endTime"
                order={order}
                sortHandler={createSortHandler("endTime")}
              />
              <SortableCell
                title="Kesto"
                selectedOrderBy={orderBy}
                orderBy="durationSeconds"
                order={order}
                sortHandler={createSortHandler("durationSeconds")}
              />
              <SortableCell
                title="Koneellinen lattiapesu"
                selectedOrderBy={orderBy}
                orderBy="floorMachineWashed"
                order={order}
                sortHandler={createSortHandler("floorMachineWashed")}
              />
              <SortableCell
                title="Menetelmä"
                selectedOrderBy={orderBy}
                orderBy="method"
                order={order}
                sortHandler={createSortHandler("method")}
              />
              <SortableCell
                title="NFC-tunniste"
                selectedOrderBy={orderBy}
                orderBy="nfcId"
                order={order}
                sortHandler={createSortHandler("nfcId")}
              />
              <SortableCell
                title="Paikkakunta"
                selectedOrderBy={orderBy}
                orderBy="workLocation"
                order={order}
                sortHandler={createSortHandler("workLocation")}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const durationText = seconds2time(row.durationSeconds)
                return (
                  <TableRow hover key={index}>
                    <TableCell
                      sx={{ maxWidth: 250 }}
                      component="th"
                      scope="row"
                    >
                      {cleaningTypeToText(row.cleaningType)}
                    </TableCell>
                    <TableCell align="left">{row.vehicle}</TableCell>
                    <TableCell align="left">{row.startTime}</TableCell>
                    <TableCell align="left">{row.endTime}</TableCell>
                    <TableCell align="left">{durationText}</TableCell>
                    <TableCell align="left">{row.floorMachineWashed}</TableCell>
                    <TableCell align="left">{row.method}</TableCell>
                    <TableCell align="left">{row.nfcId}</TableCell>
                    <TableCell align="left">{row.workLocation}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[200]}
        component="div"
        count={props.cleanings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={() => { }}
      />
    </Box>
  </>
}

function seconds2time(durationInSeconds: number) {
  let hours = Math.floor(durationInSeconds / 3600)
  let minutes = Math.floor((durationInSeconds - hours * 3600) / 60)
  let seconds = durationInSeconds - hours * 3600 - minutes * 60
  var time = ""

  if (hours !== 0) {
    time = hours + "h "
  }
  if (minutes !== 0 || time !== "") {
    time += minutes + "min "
  }

  time += seconds + "s"

  return time
}

function SortableCell(props: {
  title: string
  selectedOrderBy: keyof CleaningRow
  orderBy: keyof CleaningRow
  order: Order
  sortHandler: (event: React.MouseEvent<unknown>) => void
}) {
  return (
    <TableCell
      sortDirection={
        props.selectedOrderBy === props.orderBy ? props.order : false
      }
      align="left"
    >
      <TableSortLabel
        active={props.selectedOrderBy === props.orderBy}
        direction={
          props.selectedOrderBy === props.orderBy ? props.order : "asc"
        }
        onClick={props.sortHandler}
      >
        {props.title}
      </TableSortLabel>
    </TableCell>
  )
}

function cleaningTypeToText(type: string): string {
  switch (type) {
    case "NK":
      return "Nopea kääntymäsiivous (NK)"
    case "NK+LM":
      return "Nopea kääntymäsiivous ja lattioiden moppaus (NK+LM)"
    case "RT":
      return "Roskakorien tyhjennys (RT)"
    case "LKP":
      return "Lattioiden koneellinen pesu (LKP)"
    case "KYS":
      return "Kevyt ylläpitosiivous (KYS)"
    case "WC":
      return "WC-pesu (WC)"
    case "PS":
      return "Päivystyssiivous (PS)"
    case "TG":
      return "Tussigraffitien ja tarrojen poisto (TG)"
    case "PV":
      return "Penkkien vaihto liikenteessä (PV)"
    case "RV":
      return "Roskakorien vaihto liikenteessä (RV)"
    case "YPS":
      return "Ylläpitosiivous (YPS)"
    case "PSS":
      return "Perussiivous (PSS)"
    case "KVH":
      return "Käyttövalmiushuolto (KVH)"
    case "JLP":
      return "Jaksollinen lattianpesu (JLP)"
    case "OSPSS":
      return "Ohjaamon siivous perussiivouksen yhteydessä (OSPSS)"
    case "OSVK":
      return "Ohjaamoiden viikoittainen siivous ja tarvike täydennys (OSVK)"
    case "OSR":
      return "Nopea roskien tyhjennys (OSR)"
    default:
      return type
  }
}
