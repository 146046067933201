import { DomainContext } from "../../domain/DomainContext"
import { ClientFilterOption, InspectionsState, InspectionsViewModel } from "./InspectionsViewModel"
import React, { useEffect, useState, useContext } from "react"
import { InspectionsTable } from "./InspectionsTable"
import { PeriodOption, Timeselector } from "../cleanings/TimeSelector"
import { ExportButton } from "./ExportButton"
import { Box, LinearProgress } from "@mui/material"
import { Filters } from './Filters'
import { InspectionType } from '../../domain/entities/Inspection'

export function useViewModel(): [InspectionsState, InspectionsViewModel] {
  const { getInspectionsViewModel } = useContext(DomainContext)
  const [viewModel] = useState(() => getInspectionsViewModel())
  const [state, setState] = useState<InspectionsState>(
    viewModel.currentState()
  )

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [viewModel])

  return [state, viewModel]
}

export const InspectionsPage = () => {
  const [state, viewModel] = useViewModel()

  const loadingBar = () => {
    if (state.type === "loading") {
      return <LinearProgress />
    }
  }

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={1}>
        <Timeselector
          selectTimePeriod={(period: PeriodOption) =>
            viewModel.selectTimePeriod(period)
          }
        />
        <Filters
          inspectionTypeFilters={state.inspectionTypeFilters}
          clientFilters={state.clientFilters}
          toggleInspectionTypeFilter={(type: InspectionType) => viewModel.toggleInspectionTypeFilter(type)}
          toggleClientFilter={(type: ClientFilterOption) => viewModel.toggleClientFilter(type)}
        />
        <ExportButton inspections={state.inspections} />
      </Box>
      <InspectionsTable inspections={state.inspections} />
    </>
  )
}
