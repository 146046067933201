
import { Backend, BackendResult, UserData } from '../domain/interfaces/Backend'
import { API, Auth } from "aws-amplify"
import { UserGroup } from '../domain/entities/User'
import { Cleaning } from '../domain/entities/Cleaning'
import { CleaningRequest } from '../domain/entities/CleaningRequest'
import { Inspection } from '../domain/entities/Inspection'
import axios from 'axios'
import config from '../config'

export class BackendImpl implements Backend {

  private tarmoToken: TarmoToken | undefined = undefined

  async listUsers(): Promise<BackendResult<UserData[]>> {
    try {
      const session = await Auth.currentSession()
      const response = await API.get("client-api", "/users", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
      })

      const users: {
        name: string
        email: string
        group?: string
        createdAt: number
      }[] = response

      const result: UserData[] = users.map((user) => {
        let group: UserGroup | undefined
        if (user.group === "cleaner") group = "cleaner"
        else if (user.group === "observer") group = "observer"
        else if (user.group === "supervisor") group = "supervisor"
        else group = undefined

        return {
          name: user.name,
          email: user.email,
          group: group,
        } as UserData
      })

      return { success: true, data: result }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async updateUserGroup(email: string, group: UserGroup): Promise<BackendResult<void>> {
    try {
      const session = await Auth.currentSession()
      await API.post("client-api", "/groups/" + group + "/users", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        body: { email: email },
      })

      return { success: true, data: undefined }

    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async deleteUser(email: string): Promise<BackendResult<void>> {
    try {
      const session = await Auth.currentSession()
      await API.del("client-api", "/users/" + email, {
        headers: { Authorization: session.getIdToken().getJwtToken() },
      })

      return { success: true, data: undefined }

    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async createUser(email: string, name: string, group: UserGroup): Promise<BackendResult<void>> {
    try {
      const session = await Auth.currentSession()
      await API.post("client-api", "/users", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        body: {
          email: email,
          name: name,
          group: group
        }
      })

      return { success: true, data: undefined }

    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async closeRequest(id: string): Promise<BackendResult<void>> {
    try {
      const session = await Auth.currentSession()
      await API.post("client-api", "/cleaning-requests/" + id + "/complete", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        body: {
          timestamp: Date.now()
        }
      })

      return { success: true, data: undefined }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async listCleanings(from: number, to: number): Promise<BackendResult<Cleaning[]>> {
    try {
      const session = await Auth.currentSession()
      const response = await API.get("client-api", "/cleanings", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        queryStringParameters: {
          from: from,
          to: to
        },
      })

      return { success: true, data: response as Cleaning[] }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async listClosedCleaningRequests(from: number, to: number): Promise<BackendResult<CleaningRequest[]>> {
    try {
      const session = await Auth.currentSession()
      const response = await API.get("client-api", "/completed-cleaning-requests", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        queryStringParameters: {
          from: from,
          to: to
        },
      })

      const requests: {
        id: string
        vehicle: string
        category: {
          fi: string
        }
        description: string
        createdAt: number
        allocated: boolean
        cleaningTypeId: string
        images: string[]
        locationText: {
          fi: string
        }
        closedAt?: number
        workLocation?: string
        comment?: string
        closedByGroup?: string
      }[] = response

      const result: CleaningRequest[] = requests.map((it) => {
        return {
          id: it.id,
          vehicle: it.vehicle,
          category: it.category.fi,
          description: it.description,
          createdAt: it.createdAt,
          allocated: it.allocated,
          cleaningTypeId: it.cleaningTypeId,
          images: it.images,
          location: it.locationText.fi,
          closedAt: it.closedAt,
          workLocation: it.workLocation,
          comment: it.comment,
          closedByGroup: it.closedByGroup
        }
      })

      return { success: true, data: result }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async listOpenCleaningRequests(): Promise<BackendResult<CleaningRequest[]>> {
    try {
      const session = await Auth.currentSession()
      const response = await API.get("client-api", "/cleaning-requests", {
        headers: { Authorization: session.getIdToken().getJwtToken() },
      })

      const requests: {
        id: string
        vehicle: string
        category: {
          fi: string
        }
        description: string
        createdAt: number
        allocated: boolean
        cleaningTypeId: string
        images: string[]
        locationText: {
          fi: string
        }
      }[] = response

      const result: CleaningRequest[] = requests.map((it) => {
        return {
          id: it.id,
          vehicle: it.vehicle,
          category: it.category.fi,
          description: it.description,
          createdAt: it.createdAt,
          allocated: it.allocated,
          cleaningTypeId: it.cleaningTypeId,
          images: it.images,
          location: it.locationText.fi
        }
      })

      return { success: true, data: result }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  async listInspections(from: number, to: number): Promise<BackendResult<Inspection[]>> {
    try {
      if (this.tarmoToken === undefined || this.tarmoToken.expires < Date.now() - 1000 * 60 * 10) {
        this.tarmoToken = await this.getTarmoToken()
      }

      const response = await axios.get<InspectionDTO[]>(config.tarmoClientApiUrl + '/inspections?from=' + from + "&to=" + to, {
        headers: {
          Authorization: this.tarmoToken.token
        }
      })

      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        throw Error('Listing inspections failed')
      }
    } catch (_error) {
      return { success: false, reason: 'unexpected-error' }
    }
  }

  private async getTarmoToken(): Promise<TarmoToken> {
    const session = await Auth.currentSession()
    const response = await API.post("client-api", "/login/tarmo-token", {
      headers: { Authorization: session.getIdToken().getJwtToken() },
    })
    return response as TarmoToken
  }
}

export type InspectionDTO = {
  type: 'maintenance-hangar-inspection' | 'terminus-inspection' | 'daily-inspection'
  vehicle: string
  startTimestamp: number
  endTimestamp: number
  client: 'KOTA' | 'MOPPI'
  nfcUsed: boolean
}

type TarmoToken = {
  token: string
  expires: number
}