import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,  
  Menu,
  MenuItem,  
  Box,
  CssBaseline,
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GroupIcon from "@mui/icons-material/Group";
import { AccountCircle, CheckCircle, Train, Visibility, Warning } from "@mui/icons-material";
import Assignment from "@mui/icons-material/AssignmentOutlined";
import { Auth } from "aws-amplify";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),  
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface DrawerProps {
  path: string | string[] | readonly string[] | undefined;
}

const drawerWidth = 220;

export const MoppiDrawer: React.FunctionComponent<DrawerProps> = (props) => {

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const isUserMenuOpen = Boolean(userAnchorEl);

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const titles = (path: string | readonly string[] | undefined) => {
    switch (path) {
      case "/":
        return "Etusivu";
      case "/users":
        return "Käyttäjät";
      case "/cleanings":
        return "Siivoukset";
      case "/requests":
        return "Avoimet siivouspyynnöt";
      case "/closed-requests":
        return "Suljetut siivouspyynnöt";
      case "/vehicles":
        return "Rungot";
      case "/inspections":
        return "Tarkastukset"
        case "/requests/:id":
          return "Siivouspyynnön tiedot"
      default: {          
        return "";
      }
    }
  };

  const renderUserMenu = (
    <Menu
      anchorEl={userAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={"user-menu"}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isUserMenuOpen}
      onClose={handleUserMenuClose}
    >
      <MenuItem onClick={() => Auth.signOut()}>Kirjaudu ulos</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        color="secondary"
        position="fixed"   
        open={open}     
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"            
            size="large"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
            >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            color="inherit"
            noWrap
            component="div"            
            sx={{ flexGrow: 1 }}
          >
            {"MOPPI - " + titles(props.path)}
          </Typography>

            
          <IconButton color="inherit" onClick={handleUserMenuOpen} size="large" >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderUserMenu}
      <Drawer
        variant="permanent"
        open={open}        
      >        
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          <ListItem button key="cleanings" component={Link} to="/cleanings">
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Siivoukset" />
          </ListItem>
          <ListItem button key="vehicles" component={Link} to="/vehicles">
            <ListItemIcon>
              <Train />
            </ListItemIcon>
            <ListItemText primary="Rungot" />
          </ListItem>
          <ListItem button key="requests" component={Link} to="/requests">
            <ListItemIcon>
              <Warning />
            </ListItemIcon>
            <ListItemText primary="Siivouspyynnöt" />
          </ListItem>
          <ListItem button key="closed-requests" component={Link} to="/closed-requests">
            <ListItemIcon>
              <CheckCircle />
            </ListItemIcon>
            <ListItemText primary="Suljetut pyynnöt" />
          </ListItem>
          <ListItem button key="inspections" component={Link} to="/inspections">
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText primary="Tarkastukset" />
          </ListItem>
          <ListItem button key="users" component={Link} to="/users">
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Käyttäjät" />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
};
