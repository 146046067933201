import { Cleaning } from '../entities/Cleaning'
import { Backend } from '../interfaces/Backend'

export interface ListCleanings {
  execute(from: number, to: number): Promise<Cleaning[]>
}

export class ListCleaningsImpl implements ListCleanings {

  constructor(
    private readonly backend: Backend
  ) {}


  async execute(from: number, to: number): Promise<Cleaning[]> {
    const result = await this.backend.listCleanings(from, to)

    if (result.success) {
      return result.data
    } else {
      return []
    }
  }
}
