import { User } from '../entities/User'
import { Backend } from '../interfaces/Backend'

export interface ListUsers {
  execute(): Promise<User[]>
}

export class ListUsersImpl implements ListUsers {

  constructor(
    private readonly backend: Backend
  ) {}

  async execute(): Promise<User[]> {

    const usersResult = await this.backend.listUsers()

    if (usersResult.success) {
      return usersResult.data.map((user) => {
        return {
          email: user.email,
          name: user.name,
          group: user.group ?? "cleaner"
        }
      })
    } else {
      throw new Error("loading failed")
    }
  }

}