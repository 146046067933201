import { DomainContext } from "../../domain/DomainContext";
import { CleaningsState, CleaningsViewModel } from "./CleaningsViewModel";
import React, { useEffect, useState, useContext } from "react";
import { CleaningsTable } from "./CleaningsTable";
import { PeriodOption, Timeselector } from "./TimeSelector";
import { ExportButton } from "./ExportButton";
import { Box, LinearProgress } from "@mui/material";

export function useViewModel(): [CleaningsState, CleaningsViewModel] {
  const { getCleaningsViewModel } = useContext(DomainContext);
  const [viewModel] = useState(() => getCleaningsViewModel());
  const [state, setState] = useState<CleaningsState>(
    viewModel.currentState()
  );

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState);

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [viewModel]);

  return [state, viewModel];
}

export const CleaningsPage = () => {
  const [state, viewModel] = useViewModel();

  const loadingBar = () => {
    if (state.type === "loading") {
      return <LinearProgress />;
    }
  };

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={1}>
        <Timeselector
          selectTimePeriod={(period: PeriodOption) =>
            viewModel.selectTimePeriod(period)
          }
        />
        <ExportButton cleanings={state.cleanings} />
      </Box>
      <CleaningsTable cleanings={state.cleanings} />
    </>
  );
};
