import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
} from "@mui/material";
import { UserGroup } from "../../domain/entities/User";

export interface EditUserProps {
  email: string;
  name: string;
  group: UserGroup;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onUpdateUserGroup: (group: UserGroup) => void;
}

export const EditUserDialog = (props: EditUserProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Muokkaa käyttäjää</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.email} ({props.name})
        </DialogContentText>

        <Select
          value={props.group}
          onChange={(event) =>
            props.onUpdateUserGroup(event.target.value as UserGroup)
          }
          fullWidth
        >
          <MenuItem value={"cleaner"}>Siivous</MenuItem>
          <MenuItem value={"supervisor"}>Työnjohto</MenuItem>
          <MenuItem value={"observer"}>Katselu</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Peruuta
        </Button>
        <Button onClick={props.onSave} color="primary">
          Tallenna
        </Button>
      </DialogActions>
    </Dialog>
  );
};
