import { Box, Button, LinearProgress, TextField } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { DomainContext } from "../../domain/DomainContext";
import { EditUserDialog } from "./EditUserDialog";
import { UsersState, UsersViewModel } from "./UsersViewModel";
import Snackbar from "@mui/material/Snackbar";
import { UserGroup } from "../../domain/entities/User";
import { UserTable } from "./UserTable";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { CreateUserDialog } from "./CreateUserDialog";

export function useViewModel(): [UsersState, UsersViewModel] {
  const { getUsersViewModel } = useContext(DomainContext);
  const [viewModel] = useState(() => getUsersViewModel());
  const [state, setState] = useState<UsersState>(
    viewModel.currentState()
  );

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState);

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [viewModel]);

  return [state, viewModel];
}

export const UsersPage = () => {
  const [state, viewModel] = useViewModel();

  const loadingBar = () => {
    if (state.type === "loading") {
      return <LinearProgress />;
    }
  };

  const notification = () => {
    let message: string | undefined = undefined;

    switch (state.notification) {
      case "loading-failed": {
        message = "Käyttäjien lataus epäonnistui. Ole hyvä ja yritä uudestaan.";
        break;
      }
      case "updating-user-failed": {
        message = "Tallennus epäonnistui. Ole hyvä ja yritä uudestaan.";
        break;
      }
      case "deleting-user-failed": {
        message =
          "Käyttäjän poistaminen epäonnistui. Ole hyvä ja yritä uudestaan.";
        break;
      }
      case "creating-user-failed": {
        message =
          "Käyttäjän luominen epäonnistui. Ole hyvä ja yritä uudestaan.";
        break;
      }
      case "user-deleted": {
        message = "Käyttäjä poistettu";
        break;
      }
      case "user-updated": {
        message = "Käyttäjän tiedot päivitetty";
        break;
      }
      case "user-created": {
        message = "Käyttäjän luotu";
        break;
      }
    }

    if (message !== undefined) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={true}
          autoHideDuration={4000}
          onClose={() => viewModel.cancelNotification()}
          message={message}
        />
      );
    }
  };

  const users = state.users.map((user) => {
    return {
      name: user.name,
      email: user.email,
      group: {
        id: user.group,
        name: userGroupToName(user.group),
      },
    };
  });

  return (
    <>
      {loadingBar()}
      {notification()}
      {state.type === "edit-user" && state.userData?.group !== undefined && (
        <EditUserDialog
          open={true}
          email={state.userData.email}
          name={state.userData.name}
          group={state.userData.group}
          onClose={() => viewModel.cancelEditing()}
          onSave={() => viewModel.saveSelectedUser()}
          onUpdateUserGroup={(group: UserGroup) =>
            viewModel.updateUserGroup(group)
          }
        />
      )}
      {state.type === "create-user" && state.userData !== undefined && (
        <CreateUserDialog
          open={true}
          email={state.userData.email}
          name={state.userData.name}
          group={state.userData.group}
          onClose={() => viewModel.cancelEditing()}
          onSave={() => viewModel.confirmUserCreation()}
          onUpdateUserGroup={(group: UserGroup) =>
            viewModel.updateUserGroup(group)
          }
          onUpdateEmail={(email: string) => viewModel.updateUserEmail(email)}
          onUpdateName={(name: string) => viewModel.updateUserName(name)}
        />
      )}
      {state.type === "confirm-delete-user" && state.userData !== undefined && (
        <DeleteUserDialog
          open={true}
          email={state.userData.email}
          name={state.userData.name}
          onClose={() => viewModel.cancelEditing()}
          onConfirm={() => viewModel.deleteSelectedUser()}
        />
      )}
      <Box m={2} pb={4}>
        <TextField
          style={{width: 300}}
          variant="outlined"
          id="search"
          name="search"
          label="Haku"
          type="text"                    
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {viewModel.updateSearchText(event.target.value)}}
          value={state.searchText}
        />
        <Button
          variant="outlined"
          color="primary"          
          onClick={() => viewModel.openUserCreation()}
          sx={{float: "right"}}
        >
          Lisää käyttäjä
        </Button>
      </Box>
      <UserTable
        users={users}        
        onEditButtonClicked={(row) => viewModel.selectUserForEditing(row.email)}
        onDeleteButtonClicked={(row) =>
          viewModel.selectUserForDeleting(row.email)
        }
      />
    </>
  );
};

export function userGroupToName(group: UserGroup): string {
  switch (group) {
    case "cleaner":
      return "Siivous";
    case "observer":
      return "Katselu";
    case "supervisor":
      return "Työnjohto";
  }
}