import { BehaviorSubject } from 'rxjs'
import { User, UserGroup } from '../../domain/entities/User'
import { CreateUser } from '../../domain/usecases/CreateUser'
import { DeleteUser } from '../../domain/usecases/DeleteUser'
import { ListUsers } from '../../domain/usecases/ListUsers'
import { UpdateUser } from '../../domain/usecases/UpdateUser'

export type UsersState = {
  type: 'loading' | 'show-users' | 'edit-user' | 'create-user' | 'confirm-delete-user'
  users: User[]
  userData?: {
    name: string
    email: string
    group?: UserGroup
  },
  searchText: string
  notification? : Notification 
} 

export type Notification = "user-created" | "user-updated" | "user-deleted" | "loading-failed" | "updating-user-failed" | "deleting-user-failed" | "creating-user-failed"

export class UsersViewModel {

    private _state = new BehaviorSubject<UsersState>({ type: 'loading', users: [], userData: undefined, searchText: "" })

    readonly state = this._state.asObservable()

    private _users: User[] = []

    constructor(
      private readonly listUsers: ListUsers, 
      private readonly updateUser: UpdateUser,
      private readonly deleteUser: DeleteUser,
      private readonly createUser: CreateUser
    ) {
      this.refreshUsers()
    }

    currentState(): UsersState {
      return this._state.getValue()
    }

    refreshUsers() {
      const currentState = this._state.value

      this.listUsers.execute()
        .then((result) => {
          this._users = result.sort((a, b) => a.name.localeCompare(b.name))
          this._state.next({type: 'show-users', users: this._users.filter((it) => this.matchesSearchText(it, currentState.searchText)), notification: currentState.notification, searchText: currentState.searchText})
        })
        .catch((_error) => this._state.next({type: 'show-users', users: [], notification: "loading-failed", searchText: currentState.searchText}))
    }

    private matchesSearchText(user: User, searchText: string): boolean {
      if (searchText === "") {
        return true
      }

      return user.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || user.email.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    }

    cancelNotification() {
      const currentState = this._state.value

      this._state.next({
        ...currentState,
        notification: undefined
      })
    }

    selectUserForEditing(email: string) {
      const currentState = this._state.value

      const user = currentState.users.find((user) => user.email === email)

      if (user !== undefined) {
        this._state.next({
          ...currentState,
          type: 'edit-user',
          userData: {
            email: email,
            name: user.name,
            group: user.group
          }
        })
      }
    }

    selectUserForDeleting(email: string) {
      const currentState = this._state.value

      const user = currentState.users.find((user) => user.email === email)

      if (user !== undefined) {
        this._state.next({
          ...currentState,
          type: 'confirm-delete-user',
          userData: {
            email: email,
            name: user.name,
            group: user.group
          }
        })
      }
    }

    cancelEditing() {
      const currentState = this._state.value
      this._state.next({
        ...currentState,
        type: 'show-users',
        userData: undefined
      })
    }

    saveSelectedUser() {
      const currentState = this._state.value

      if (currentState.userData !== undefined && currentState.userData.group !== undefined) {
        
        this._state.next({
          ...currentState,
          type: 'loading',
          userData: undefined
        })

        this.updateUser
        .execute(currentState.userData.email, currentState.userData.group)
        .then((_result) => {

          this._state.next({
            ...this._state.value,
            type: 'loading',
            userData: undefined,
            notification: 'user-updated'
          })

          this.refreshUsers()
        })
        .catch((_error) => {
          this._state.next({
            ...currentState,
            type: 'show-users',
            notification: 'updating-user-failed'
          })
        })
      }
    }

    updateSearchText(text: string) {
      const currentState = this._state.value
      this._state.next({
          ...currentState,
          users: this._users.filter((it) => this.matchesSearchText(it, text)),
          searchText: text
      })       
    }

    updateUserGroup(group: UserGroup) {
      const currentState = this._state.value

      if (currentState.userData !== undefined) {
       this._state.next({
          ...currentState,
          userData: {
            ...currentState.userData,
            group: group
          }
        })
      }
    }

    deleteSelectedUser() {
      const currentState = this._state.value

      if (currentState.userData !== undefined) {
        
        this._state.next({
          ...currentState,
          type: 'loading',
          userData: undefined
        })

        this.deleteUser
        .execute(currentState.userData.email)
        .then((_result) => {

          this._state.next({
            ...this._state.value,
            type: 'loading',
            userData: undefined,
            notification: 'user-deleted'
          })

          this.refreshUsers()
        })
        .catch((_error) => {
          this._state.next({
            ...currentState,
            type: 'show-users',
            notification: 'deleting-user-failed'
          })
        })
      }
    }

    openUserCreation() {
      const currentState = this._state.value
      this._state.next({
        ...currentState,
        type: 'create-user',
        userData: {
          name: "",
          email: "",
          group: undefined
        }
      })
    }

    updateUserName(name: string) {
      const currentState = this._state.value

      if (currentState.userData !== undefined) {
       this._state.next({
          ...currentState,
          userData: {
            ...currentState.userData,
            name: name
          }
        })
      }
    }


    updateUserEmail(email: string) {
      const currentState = this._state.value

      if (currentState.userData !== undefined) {
       this._state.next({
          ...currentState,
          userData: {
            ...currentState.userData,
            email: email
          }
        })
      }
    }

    confirmUserCreation() {
      const currentState = this._state.value
      if (currentState.userData?.group !== undefined) {

        this._state.next({
          ...currentState,
          type: 'loading',
          userData: undefined
        })


        this.createUser
        .execute(currentState.userData.email, currentState.userData.name, currentState.userData.group)
        .then((_result) => {

          this._state.next({
            ...this._state.value,
            type: 'loading',
            userData: undefined,
            notification: 'user-created'
          })

          this.refreshUsers()
        })
        .catch((_error) => {
          this._state.next({
            ...currentState,
            type: 'show-users',
            notification: 'creating-user-failed'
          })
        })
      }
    }
}
