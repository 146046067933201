import { UserGroup } from '../entities/User'
import { Backend } from '../interfaces/Backend'

export interface CreateUser {
  execute(email: string, name: string, group: UserGroup): Promise<void>
}

export class CreateUserImpl implements CreateUser {

  constructor(
    private readonly backend: Backend
  ) {}

  async execute(email: string, name: string, group: UserGroup): Promise<void> {

    if (email.length < 5) {
      throw new Error("Invalid email")
    }

    if (name.length < 2) {
      throw new Error("Invalid name")
    }

    const result = await this.backend.createUser(email, name, group)

    if (!result.success) {
      throw new Error("Create failed")
    }
  }

}