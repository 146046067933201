import { CleaningRequest } from '../entities/CleaningRequest'
import { Backend } from './Backend'

export interface OpenCleaningRequestRepository {
  listOpenCleaningRequests: () => Promise<CleaningRequest[]>
  getCleaningRequest: (id: string) => Promise<CleaningRequest | undefined>
  closeCleaningRequest: (id: string) => Promise<boolean>
}

export class InMemoryOpenCleaningRequestRepository implements OpenCleaningRequestRepository {

  private requests: CleaningRequest[] = []
  private lastUpdated: number | undefined = undefined

  constructor(private backend: Backend) {}

  async listOpenCleaningRequests(): Promise<CleaningRequest[]> {
    if (this.lastUpdated === undefined || this.lastUpdated + 1000 * 30 < Date.now()) {
      const backendResult = await this.backend.listOpenCleaningRequests()

      if (backendResult.success) {
        this.lastUpdated = Date.now()
        this.requests = backendResult.data
      }
    }

    return this.requests
  }

  async getCleaningRequest(id: string): Promise<CleaningRequest | undefined> {
    const request = this.requests.find((it) => it.id === id)

    if (request !== undefined) {
      return request
    } else {
      const backendResult = await this.backend.listOpenCleaningRequests()

      if (backendResult.success) {
        this.lastUpdated = Date.now()
        this.requests = backendResult.data
      }
    }

    return this.requests.find((it) => it.id === id)
  }

  async closeCleaningRequest(id: string): Promise<boolean> {
    const result = await this.backend.closeRequest(id)

    if (result.success) {
      this.requests = this.requests.filter((it) => it.id !== id)
      return true
    } else {
      return false
    }    
  }
}