import { Box } from "@mui/material";
import React from "react";

export function VehiclesPage() {
  return (
    <Box m={2}>
      <h2>Tulossa oleva ominaisuus</h2>
    </Box>
  );
}
