import { Button } from "@mui/material";
import { ExportToCsv } from "export-to-csv";
import { CleaningRequest } from "../../domain/entities/CleaningRequest";
import moment from "moment";

type Props = {
  requests: CleaningRequest[];
};

export function ExportButton(props: Props) {

  const exportCsv = () => {
    const options = {
      filename: "MOPPI_avoimet_pyynnot",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    const data = props.requests.map((request) => {
      return {
        vehicle: request.vehicle,
        "created at": moment(request.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        "vikaosio ID": request.id,
        "cleaning type": request.cleaningTypeId ?? "-",
        category: request.category,
        location: request.location,
        desciption: request.description,
      };
    });
    csvExporter.generateCsv(data);
  };

  return (
    <Button
      variant="outlined"
      color="primary"      
      onClick={exportCsv}
      sx={{ float: "right"}}
    >
      Lataa CSV
    </Button>
  );
}
