import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export interface CloseRequestProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const CloseRequestDialog: React.FunctionComponent<CloseRequestProps> = (
  props
) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle id="form-dialog-title">Päätä siivouspyyntö</DialogTitle>   
      <DialogContent>
        <DialogContentText>
          Oletko varma?
        </DialogContentText>
      </DialogContent>   
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Peruuta
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          Kyllä
        </Button>
      </DialogActions>
    </Dialog>
  );
};
