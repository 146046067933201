import { BehaviorSubject } from "rxjs";
import moment from "moment";
import { CleaningRequest } from '../../domain/entities/CleaningRequest'
import { ListClosedCleaningRequests } from '../../domain/usecases/ListClosedCleaningRequests'

export type ClosedRequestsState = {
  type: "loading" | "show-requests";
  timeSelectionState:
    | {
        type:
          | "today"
          | "yesterday"
          | "this-week"
          | "last-week"
          | "this-month"
          | "last-month";
      }
    | {
        type: "custom";
        from: number;
        to: number;
      };
  requests: CleaningRequest[];
};

export class ClosedRequestsViewModel {
  private _state = new BehaviorSubject<ClosedRequestsState>({
    type: "loading",
    timeSelectionState: { type: "today" },
    requests: [],
  });

  readonly state = this._state.asObservable();

  constructor(private readonly listClosedRequests: ListClosedCleaningRequests) {
    this.refreshRequests();
  }

  currentState(): ClosedRequestsState {
    return this._state.getValue();
  }

  selectTimePeriod(
    period:
      | "today"
      | "yesterday"
      | "this-week"
      | "last-week"
      | "this-month"
      | "last-month"
  ) {
    const currentState = this._state.value;
    this._state.next({ ...currentState, timeSelectionState: { type: period } });
    this.refreshRequests();
  }

  refreshRequests() {
    const currentState = this._state.value;

    this._state.next({ ...currentState, type: "loading" });

    let from: number;
    let to: number;

    switch (currentState.timeSelectionState.type) {
      case "today":
        from = moment().startOf("day").valueOf();
        to = moment().endOf("day").valueOf();
        break;
      case "yesterday":
        from = moment().subtract(1, "days").startOf("day").valueOf();
        to = moment().subtract(1, "days").endOf("day").valueOf();
        break;
      case "this-week":
        from = moment().startOf("week").valueOf();
        to = moment().endOf("week").valueOf();
        break;
      case "last-week":
        from = moment().subtract(1, "week").startOf("week").valueOf();
        to = moment().subtract(1, "week").endOf("week").valueOf();
        break;
      case "this-month":
        from = moment().startOf("month").valueOf();
        to = moment().endOf("month").valueOf();
        break;
      case "last-month":
        from = moment().subtract(1, "month").startOf("month").valueOf();
        to = moment().subtract(1, "month").endOf("month").valueOf();
        break;
      case "custom":
        from = currentState.timeSelectionState.from;
        to = currentState.timeSelectionState.to;
        break;
    }

    this.listClosedRequests
      .execute(from, to)
      .then((result) =>
        this._state.next({
          type: "show-requests",
          requests: result,
          timeSelectionState: currentState.timeSelectionState,
        })
      )
      .catch((_error) =>
        this._state.next({
          type: "show-requests",
          requests: [],
          timeSelectionState: currentState.timeSelectionState,
        })
      );
  }
}
