import { Backend } from '../interfaces/Backend'

export interface DeleteUser {
  execute(email: string): Promise<void>
}

export class DeleteUserImpl implements DeleteUser {

  constructor(
    private readonly backend: Backend
  ) {}
  
  async execute(email: string): Promise<void> {
    const result = await this.backend.deleteUser(email)

    if (!result.success) {
      throw new Error("Delete failed")
    }
  }

}