const prod = {
  clientApiGateway: {
    URL: "https://laxyjigoaj.execute-api.eu-west-1.amazonaws.com/prod/api/moppi-client"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_iMo4zqeos",
    USER_POOL_CLIENT: "r1bt4p10a46r0vjieqfc9tlte",
    DOMAIN: "lahiliikenne-siivous-prod-vr-fi.auth.eu-west-1.amazoncognito.com",
    REDIRECT_SIGN_IN: 'https://lahiliikenne-siivous.vr.fi',
    REDIRECT_SIGN_OUT: 'https://lahiliikenne-siivous.vr.fi/logout',
  },
  tarmoClientApiUrl: "https://w1xr4pjutl.execute-api.eu-west-1.amazonaws.com/prod/api/client/v1"
}

const test = {
  clientApiGateway: {
    URL: "https://6o9yxt1dzk.execute-api.eu-west-1.amazonaws.com/test/api/moppi-client"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_sXfcXSuox",
    USER_POOL_CLIENT: "5mmanqfr3rvugfnb2kvdee58ms",
    DOMAIN: "lahiliikenne-siivous-test-vr-fi.auth.eu-west-1.amazoncognito.com",
    REDIRECT_SIGN_IN: 'https://test-lahiliikenne-siivous.vr.fi',
    REDIRECT_SIGN_OUT: 'https://test-lahiliikenne-siivous.vr.fi/logout',
  },
  tarmoClientApiUrl: "https://lo0r1uyffl.execute-api.eu-west-1.amazonaws.com/test/api/client/v1"
}

const dev = {
  clientApiGateway: {
    URL: "https://6o9yxt1dzk.execute-api.eu-west-1.amazonaws.com/test/api/moppi-client"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_sXfcXSuox",
    USER_POOL_CLIENT: "5mmanqfr3rvugfnb2kvdee58ms",
    DOMAIN: "lahiliikenne-siivous-test-vr-fi.auth.eu-west-1.amazoncognito.com",
    REDIRECT_SIGN_IN: 'http://localhost:3000',
    REDIRECT_SIGN_OUT: 'http://localhost:3000/logout',
  },
  tarmoClientApiUrl: "https://lo0r1uyffl.execute-api.eu-west-1.amazonaws.com/test/api/client/v1"
}

let config = dev
switch (process.env.REACT_APP_STAGE) {
  case 'prod': {
    config = prod
    break
  }
  case 'test': {
    config = test
    break
  }
  case 'dev': {
    config = dev
    break
  }
  default: {
    config = dev
    break
  }
}

const defaultConfig = { ...config }
export default defaultConfig