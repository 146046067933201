import { CleaningRequest } from '../entities/CleaningRequest'
import { Backend } from '../interfaces/Backend'

export interface ListClosedCleaningRequests {
  execute(from: number, to: number): Promise<CleaningRequest[]>
}

export class ListClosedCleaningRequestsImpl implements ListClosedCleaningRequests {

  constructor(
    private readonly backend: Backend
  ) {}

  async execute(from: number, to: number): Promise<CleaningRequest[]> {
    const result = await this.backend.listClosedCleaningRequests(from, to)

    if (result.success) {
      return result.data
    } else {
      return []
    }
  }
}
