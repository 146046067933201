import { DomainContext } from "../../domain/DomainContext";
import React, { useEffect, useState, useContext } from "react";
import {
  ClosedRequestsViewModel,
  ClosedRequestsState,
} from "./ClosedRequestsViewModel";
import { ClosedRequestsTable } from "./ClosedRequestsTable";
import { RequestImageDialog } from "../requests/RequestImageDialog";
import { API, Auth } from "aws-amplify";
import { ExportButton } from "./ExportButton";
import { Box, LinearProgress } from "@mui/material";
import { PeriodOption, Timeselector } from './TimeSelector'

export function useViewModel(): [ClosedRequestsState, ClosedRequestsViewModel] {
  const { getClosedRequestsViewModel } = useContext(DomainContext);
  const [viewModel] = useState(() => getClosedRequestsViewModel());
  const [state, setState] = useState<ClosedRequestsState>(
    viewModel.currentState()
  );

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState);

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [viewModel]);

  return [state, viewModel];
}

export function ClosedRequestsPage() {
  const [state, viewModel] = useViewModel();

  const [image, setImage] = React.useState<string | undefined>(undefined);

  const closeDialog = () => setImage(undefined);

  const loadImage = async (id: string) => {
    // TODO: hacky solution to pass a special string here. refactor this.
    setImage("loading");
    const session = await Auth.currentSession();

    // TODO: move api call to backend class and hide it behind view model
    await API.get("client-api", "/cleaning-requests/images/" + id, {
      headers: {
        Authorization: session.getIdToken().getJwtToken(),
        accept: "image/jpeg",
        "Cache-Control": "private, max-age=31536000",
      },
      responseType: "blob",
    })
      .then((response) => setImage(URL.createObjectURL(response)))
      .catch((error) => setImage(undefined));
  };

  const loadingBar = () => {
    if (state.type === "loading") {
      return <LinearProgress />;
    }
  };

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={1}>
        <Timeselector
          selectTimePeriod={(period: PeriodOption) =>
            viewModel.selectTimePeriod(period)
          }
        />
        <ExportButton requests={state.requests} />
      </Box>
      <RequestImageDialog image={image} onClose={closeDialog} />
      <ClosedRequestsTable
        requests={state.requests}
        openImage={(id: string) => loadImage(id)}
      />
    </>
  );
}
