import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import moment from "moment";
import { Inspection } from "../../domain/entities/Inspection";

export type InspectionRow = {
  type: string;
  vehicle: string;
  startTime: string;
  endTime: string;
  durationSeconds: number;
  client: string;
  nfc: string;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

type Props = {
  inspections: Inspection[];
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function InspectionsTable(props: Props) {

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof InspectionRow>("startTime");
  const [page, setPage] = React.useState(0);

  const rowsPerPage = 200;

  const rows: InspectionRow[] = props.inspections.map((it) => {
    return {
      type: it.type,
      vehicle: it.vehicle,
      startTime: moment(it.startTimestamp).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(it.endTimestamp).format("YYYY-MM-DD HH:mm:ss"),
      durationSeconds: Math.round((it.endTimestamp - it.startTimestamp) / 1000),
      client: it.client,
      nfc: it.nfcUsed ? "Kyllä" : "Ei"
    };
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof InspectionRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const createSortHandler =
    (property: keyof InspectionRow) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return <>
    <Box m={2} pb={4}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <SortableCell
                title="Tyyppi"
                selectedOrderBy={orderBy}
                orderBy="type"
                order={order}
                sortHandler={createSortHandler("type")}
              />
              <SortableCell
                title="Runko"
                selectedOrderBy={orderBy}
                orderBy="vehicle"
                order={order}
                sortHandler={createSortHandler("vehicle")}
              />
              <SortableCell
                title="Aloitus"
                selectedOrderBy={orderBy}
                orderBy="startTime"
                order={order}
                sortHandler={createSortHandler("startTime")}
              />
              <SortableCell
                title="Lopetus"
                selectedOrderBy={orderBy}
                orderBy="endTime"
                order={order}
                sortHandler={createSortHandler("endTime")}
              />
              <SortableCell
                title="Kesto"
                selectedOrderBy={orderBy}
                orderBy="durationSeconds"
                order={order}
                sortHandler={createSortHandler("durationSeconds")}
              />
              <SortableCell
                title="Sovellus"
                selectedOrderBy={orderBy}
                orderBy="client"
                order={order}
                sortHandler={createSortHandler("client")}
              />
              <SortableCell
                title="NFC-varmennus"
                selectedOrderBy={orderBy}
                orderBy="nfc"
                order={order}
                sortHandler={createSortHandler("nfc")}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const durationText = seconds2time(row.durationSeconds);
                return (
                  <TableRow hover key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{maxWidth: 250}}
                    >
                      {typeToText(row.type)}
                    </TableCell>
                    <TableCell align="left">{row.vehicle}</TableCell>
                    <TableCell align="left">{row.startTime}</TableCell>
                    <TableCell align="left">{row.endTime}</TableCell>
                    <TableCell align="left">{durationText}</TableCell>
                    <TableCell align="left">{row.client}</TableCell>
                    <TableCell align="left">{row.nfc}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[200]}
        component="div"
        count={props.inspections.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={() => {}}
      />
    </Box>
  </>;
}

function seconds2time(durationInSeconds: number) {
  let hours = Math.floor(durationInSeconds / 3600);
  let minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
  let seconds = durationInSeconds - hours * 3600 - minutes * 60;
  var time = "";

  if (hours !== 0) {
    time = hours + "h ";
  }
  if (minutes !== 0 || time !== "") {
    time += minutes + "min ";
  }

  time += seconds + "s";

  return time;
}

function SortableCell(props: {
  title: string;
  selectedOrderBy: keyof InspectionRow;
  orderBy: keyof InspectionRow;
  order: Order;
  sortHandler: (event: React.MouseEvent<unknown>) => void;
}) {


  return (
    <TableCell
      sortDirection={
        props.selectedOrderBy === props.orderBy ? props.order : false
      }
      align="left"
    >
      <TableSortLabel
        active={props.selectedOrderBy === props.orderBy}
        direction={
          props.selectedOrderBy === props.orderBy ? props.order : "asc"
        }
        onClick={props.sortHandler}
      >
        {props.title}        
      </TableSortLabel>
    </TableCell>
  );
}

function typeToText(type: string): string {
  switch (type) {
    case "maintenance-hangar-inspection":
      return "Huoltohallitarkastus";
    case "terminus-inspection":
      return "Pääteasematarkastus";
    case "daily-inspection":
      return "Vuorokausitarkastus";    
    default:
      return type;
  }
}
