import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogContentText } from "@mui/material";

export interface RequestImageProps {
  image: string | undefined;
  onClose: () => void;
}

export const RequestImageDialog: React.FunctionComponent<RequestImageProps> = (
  props
) => {
  const content = () => {
    if (props.image === "loading") {
      return <DialogContentText>Ladataan kuvaa...</DialogContentText>;
    } else {
      return (
        <img
          src={props.image}
          height={window.innerHeight * 0.87}
          alt="Siivouspyynnön kuva"
        />
      );
    }
  };

  return (
    <Dialog
      open={props.image !== undefined}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <DialogContent>{content()}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Sulje
        </Button>
      </DialogActions>
    </Dialog>
  );
};
