import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Box,
  FormControl,
} from "@mui/material";
import { UserGroup } from "../../domain/entities/User";

export interface CreateUserProps {
  email: string;
  name: string;
  group: UserGroup | undefined;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onUpdateUserGroup: (group: UserGroup) => void;
  onUpdateName: (name: string) => void;
  onUpdateEmail: (email: string) => void;
}

export const CreateUserDialog = (props: CreateUserProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Luo uusi käyttäjä</DialogTitle>
      <DialogContent>
        <Box m={1} pb={1} />
        <TextField
          autoFocus
          variant="outlined"
          id="name"
          name="name"
          label="Nimi"
          type="text"
          fullWidth
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={props.name}
          onChange={(event) => props.onUpdateName(event.target.value)}
        />
        <Box m={1} pb={1} />
        <TextField
          variant="outlined"
          id="email"
          name="email"
          label="Sähköpostiosoite"
          type="email"
          fullWidth
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => props.onUpdateEmail(event.target.value)}
          value={props.email}
        />

        <Box m={1} pb={1} />
        <FormControl variant="outlined" style={{ minWidth: 190 }}>
          <InputLabel id="group-label">Rooli</InputLabel>
          <Select
            label="Rooli"
            value={props.group}
            onChange={(event) =>
              props.onUpdateUserGroup(event.target.value as UserGroup)
            }
            fullWidth
          >
            <MenuItem value={"cleaner"}>Siivous</MenuItem>
            <MenuItem value={"supervisor"}>Työnjohto</MenuItem>
            <MenuItem value={"observer"}>Katselu</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Peruuta
        </Button>
        <Button onClick={props.onSave} color="primary">
          Tallenna
        </Button>
      </DialogActions>
    </Dialog>
  );
};
