import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";
import { UserGroup } from "../../domain/entities/User";

type Props = {
  users: RowData[];
  onEditButtonClicked: (user: RowData) => void;
  onDeleteButtonClicked: (user: RowData) => void;  
};

export interface RowData {
  name: string;
  email: string;
  group: {
    name: string;
    id: UserGroup;
  };
}

export const UserTable = (props: Props) => {
  return (
    <>      
      <Box m={2} pb={4}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" sx={{minWidth: 650}}>
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell align="left">Sähköposti</TableCell>
                <TableCell align="left">Rooli</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.map((row) => (
                <TableRow key={row.email}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    {row.group.name}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="inherit"
                      onClick={() => props.onEditButtonClicked(row)}
                      size="large">
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      onClick={() => props.onDeleteButtonClicked(row)}
                      size="large">
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};