import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export interface DeleteUserProps {
  email: string;
  name: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteUserDialog = (props: DeleteUserProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Poista käyttäjä</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.email} ({props.name})
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Peruuta
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          Poista
        </Button>
      </DialogActions>
    </Dialog>
  );
};
