import { BehaviorSubject } from "rxjs";
import { CleaningRequest } from '../../domain/entities/CleaningRequest'
import { OpenCleaningRequestRepository } from '../../domain/interfaces/OpenCleaningRequestRepository'

export type RequestsState = {
  status: "loading" | "loaded";
  requests: CleaningRequest[]
};

export class CleaningRequestsViewModel {
  private _state = new BehaviorSubject<RequestsState>({
    status: "loading",
    requests: [],
  });

  readonly state = this._state.asObservable();

  constructor(private readonly openCleaningRequestRepository: OpenCleaningRequestRepository) {
    this.loadRequests();
  }

  currentState(): RequestsState {
    return this._state.getValue();
  }

  loadRequests() {
    const currentState = this._state.value;
    this._state.next({ ...currentState, status: "loading" });

    this.openCleaningRequestRepository
      .listOpenCleaningRequests()
      .then((result) =>
        this._state.next({
          status: "loaded",
          requests: result
        })
      )
      .catch((_error) =>
        this._state.next({
          status: "loaded",
          requests: [],
        })
      );
  }
}
