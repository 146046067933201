import { Chip, Divider, Stack } from "@mui/material";
import { InspectionType } from '../../domain/entities/Inspection'
import { ClientFilter, ClientFilterOption, InspectionTypeFilter } from './InspectionsViewModel'

type Props = {
  inspectionTypeFilters: InspectionTypeFilter[]
  clientFilters: ClientFilter[],
  toggleInspectionTypeFilter: (type: InspectionType) => void,
  toggleClientFilter: (type: ClientFilterOption) => void,
};

export function Filters(props: Props) {
  return (
    <Stack direction="row" spacing={1.5} sx={{float: "left", paddingTop: "10px", paddingLeft: "16px" }}>
      { props.inspectionTypeFilters.map((it) => FilterChip( {title: inspectionFilterText(it), selected: it.selected, toggle: () => props.toggleInspectionTypeFilter(it.type)})) }
      <Divider orientation="vertical" variant="middle" flexItem />
      { props.clientFilters.map((it) => FilterChip( {title: clientFilterText(it), selected: it.selected, toggle: () => props.toggleClientFilter(it.type)})) }
    </Stack>
  );
}

export function FilterChip(props: {title: string, selected: boolean, toggle: () => void}) {
  if (props.selected) {
    return <Chip key={props.title} label={props.title} color="primary" onClick={() => props.toggle()} />
  } else {
    return <Chip key={props.title} label={props.title} variant="outlined" onClick={() => props.toggle()} />
  }
}

function inspectionFilterText(filter: InspectionTypeFilter): string {
  switch (filter.type) {
    case "daily-inspection": return "Vuorokausitarkastus"
    case "maintenance-hangar-inspection": return "Huoltohallitarkastus"
    case "terminus-inspection": return "Pääteasematarkastus"    
  }
}

function clientFilterText(filter: ClientFilter): string {
  switch (filter.type) {    
    case "MOPPI": return "MOPPI"
    case "KOTA": return "KOTA"
  }
}