import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { domain, DomainContext } from "./domain/DomainContext";
import { Auth, Hub } from "aws-amplify";
import { MoppiDrawer } from "./ui/drawer/MoppiDrawer";
import { UsersPage } from "./ui/users/UsersPage";
import { LoginPage } from "./ui/login/LoginPage";
import { LogoutPage } from "./ui/login/LogoutPage";
import { CleaningsPage } from "./ui/cleanings/CleaningsPage";
import { VehiclesPage } from "./ui/vehicles/VehiclesPage";
import { CleaningRequestsPage } from "./ui/requests/CleaningRequestsPage";
import { InspectionsPage } from './ui/inspections/InspectionsPage'
import { ClosedRequestsPage } from './ui/closed-requests/ClosedRequestsPage'
import { CleaningRequestDetailsPage } from './ui/cleaning-request-details/CleaningRequestDetailsPage'

const theme = createTheme({
  palette: {
    primary: {
      light: "#CFF5C4",
      main: "#00B451",
      dark: "#357a38",
      contrastText: "#fff",
    },
    secondary: {
      light: "#B0B4B8",
      main: "#333333",
      dark: "#ba000d",
      contrastText: "#fff",
    },
    info: {
      main: "#0078D4"
    },
    error: {
      main: "#E85430",
    },
  },
  typography: {
    body1: {
      color: "#333333",
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "-0.05px",
      lineHeight: "21px",
    },
  },
});

const App = () => {
  const [checkingExistingAuthentication, setCheckingExistingAuthentication] =
    useState<boolean>(true);

  const { setUserHasAuthenticated } = useContext(DomainContext);

  useEffect(() => {
    const checkAuth = async () => {
      const hasAuthenticated = await Auth.currentAuthenticatedUser()
        .then((_result) => true)
        .catch((_error) => false);

      if (hasAuthenticated) {
        setUserHasAuthenticated(true);
        setCheckingExistingAuthentication(false);
      } else {
        let loginCompleteAfterRedirect = () => {
          return new Promise<boolean>((resolve, reject) => {
            Hub.listen("auth", ({ payload: { event, data } }) => {
              if (event === "signIn") {
                resolve(true);
              }
            });
            setTimeout(() => resolve(false), 1500);
          });
        };
        await loginCompleteAfterRedirect();
        const hasAuthenticated = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
          .then((_result) => true)
          .catch((_error) => false);

        setUserHasAuthenticated(hasAuthenticated);
        setCheckingExistingAuthentication(false);
      }
    };

    checkAuth();
  }, [setUserHasAuthenticated]);

  if (checkingExistingAuthentication) {
    return (
      <div>
        <p>Ladataan...</p>
      </div>
    );
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <DomainContext.Provider value={domain}>
            <Router>
              <Switch>
                <PrivateRoute exact path="/" component={MainPage}>
                  <Redirect to="/cleanings" />
                </PrivateRoute>
                <PrivateRoute exact path="/cleanings" component={CleaningsPage} />
                <PrivateRoute exact path="/inspections" component={InspectionsPage} />
                <PrivateRoute exact path="/vehicles" component={VehiclesPage} />
                <PrivateRoute path="/requests/:id" component={CleaningRequestDetailsPage} />
                <PrivateRoute
                  exact
                  path="/requests"
                  component={CleaningRequestsPage}
                />
                <PrivateRoute
                  exact
                  path="/closed-requests"
                  component={ClosedRequestsPage}
                />
                <PrivateRoute exact path="/users" component={UsersPage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/logout" component={LogoutPage} />
                <Route path="*" component={GenericNotFound} />
              </Switch>
            </Router>
          </DomainContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
};

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  const { isUserAuthenticated } = useContext(DomainContext);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isUserAuthenticated() ? (
          <div>
            <MoppiDrawer path={props.path}>
              <Component {...routeProps} />
            </MoppiDrawer>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

const GenericNotFound = () => {
  return <h2>Sivua ei löytynyt</h2>;
};

const MainPage = () => {
  return (
    <Box m={2}>
      <h2>Etusivu</h2>
    </Box>
  );
};

export default App;
